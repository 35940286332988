// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aw-js": () => import("./../../../src/pages/aw.js" /* webpackChunkName: "component---src-pages-aw-js" */),
  "component---src-pages-berserkseo-js": () => import("./../../../src/pages/berserkseo.js" /* webpackChunkName: "component---src-pages-berserkseo-js" */),
  "component---src-pages-berserkseo-signup-js": () => import("./../../../src/pages/berserkseo-signup.js" /* webpackChunkName: "component---src-pages-berserkseo-signup-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dfy-basic-add-js": () => import("./../../../src/pages/dfy-basic-add.js" /* webpackChunkName: "component---src-pages-dfy-basic-add-js" */),
  "component---src-pages-dfy-basic-js": () => import("./../../../src/pages/dfy-basic.js" /* webpackChunkName: "component---src-pages-dfy-basic-js" */),
  "component---src-pages-dfy-pro-add-js": () => import("./../../../src/pages/dfy-pro-add.js" /* webpackChunkName: "component---src-pages-dfy-pro-add-js" */),
  "component---src-pages-dfy-pro-js": () => import("./../../../src/pages/dfy-pro.js" /* webpackChunkName: "component---src-pages-dfy-pro-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hello-js": () => import("./../../../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-in-house-js": () => import("./../../../src/pages/in-house.js" /* webpackChunkName: "component---src-pages-in-house-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-password-recovery-js": () => import("./../../../src/pages/passwordRecovery.js" /* webpackChunkName: "component---src-pages-password-recovery-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

