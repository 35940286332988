import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Toaster } from "react-hot-toast"

import React, { useState, useEffect } from "react"
import { AuthProvider } from "./src/lib/firebase/firebaseCentral"
import "@fontsource/open-sans"

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <main>
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "#272727",
                color: "#fff",
              },
            },
            error: {
              style: {
                background: "#272727",
                color: "#fff",
              },
            },
          }}
        />
        {element}
      </main>
    </AuthProvider>
  )
}
